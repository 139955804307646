<template>
  <div class="more-text description">
    <div class="images row full-width">
      <div>
        <Image loading="lazy" style="flex: 1; max-width: 100%;"
               :src="image1"/>
      </div>
      <div>
        <Image style="flex: 1; max-width: 100%;" class="hidden-smd" loading="lazy" width="50%" height="100%"
               :src="image2"/>
      </div>
    </div>
    <div class="mobile-padding">
      <div class="body" :class="{active:!showLess}" style="margin-left: 0">
        <div ref="mainBody" v-html="text"></div>
        <div class="more" :class="{opened:!showLess}">
          <div class="con g-16">
            <Button type="grey"
                    :text="showLess ? $t('base.show_more') + `<img style='width: 11px;  margin-left: 10px;' src='/images/moves/expand.png'/>` : $t('base.show_less')+ `<img style='width: 11px; margin-left: 10px; transform: rotate(180deg)' src='/images/moves/expand.png'/>`"
                    @click="toggleDescription"/>

            <ProductMarkSKU :sku="sku"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "~/components/atoms/Button.vue";
import ProductMarkSKU from "~/components/molecules/ProductMarkSKU.vue";
import Image from "~/components/atoms/Image.vue";

export default {
  props: ['sku', 'text', 'image1', 'image2'],
  data() {
    return {
      maxHeight: '401px',
      currentHeight: '401px',
      showLess: true
    }
  },
  methods: {
    toggleDescription() {
      this.showLess = !this.showLess;
    }
  },
  components: {
    Image,
    ProductMarkSKU,
    Button
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins.scss';

.more-text {
  position: relative;
}

.body {
  transform: translateX(-50%);
  left: 50%;
  height: fit-content;
  min-height: 240px;
  color: $dark-black;
  max-height: 401px;
  overflow: hidden;
  transition: all .4s ease-in-out;
  background: $white;
  box-shadow: 0 32px 48px rgba(82, 82, 82, .08);
  z-index: 9;
  margin: -20% auto 0px;
  padding: 42px 40px;
  width: 777px;
  max-width: 98%;

  &.active {
    max-height: 2000px;
    padding-bottom: 200px;
  }

  @include smd {
    margin-top: -50%;
    padding: 24px;
  }
}

.more {
  position: absolute;
  bottom: 0;
  background: #fff;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 44%);
  z-index: 999;
  left: 0;
  right: 0;
  height: 265px;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 40px;

  &.opened {
    background: unset;
  }
}

.description > .image {
  width: 100%;
  aspect-ratio: 1 / .64;
  overflow: hidden;
}

.images {
  height: 657px;

  @include medium {
    height: 472px;
  }

  @include smd {
    height: auto;
  }

  & > div {
    aspect-ratio: 1/0.86;
    width: 50%;

    @include medium {
      aspect-ratio: 1/1;
      width: 100%;
      max-height: 100%;
    }

    @include smd {
      width: 100%;
    }

    &:last-child {
      @include smd {
        display: none;
      }
    }
  }
}
</style>
