<script setup>
import Text from "~/components/atoms/Text.vue";
import SpoilerTabs from "~/components/molecules/SpoilerTabs.vue";
import ProductMarkSKU from "~/components/molecules/ProductMarkSKU.vue";
import CatalogPreview from "~/components/molecules/CatalogPreview.vue";
import Button from "~/components/atoms/Button.vue";
import ExpandIcon from "~/components/icons/ExpandIcon.vue";
import ExternalLinkIcon from "~/components/icons/ExternalLinkIcon.vue";
import Help from "~/components/molecules/Help.vue";
import QuestionIcon from "~/components/icons/QuestionIcon.vue";

const {t} = useI18n();

const props = defineProps({
  sku: {type: String},
  attribute_icons: {type: Array},
  attributes: {type: Array},
  catalogs: {type: Array, default: []},
  main_category: {},
  pei_level: {},
  niche_id: {},
  pdfs: {},
  is_tile: {},
  collection_slug: {}
});


const all_attributes = props.attributes.map((item, index) => ({title: item.name, opened: index === 0})).concat([
  {
    title: `<img style='width: 12px; margin-right: 5px;' src='/images/icons/24x24/like-24x24.png'/> <span>${t('product.spoiler.0.title')}</span>`,
  },
  {title: `<img style='width: 12px; margin-right: 5px;' src='/images/icons/24x24/leaf-24x24.png'/> <span>${t('product.spoiler.1.title')}</span>`},
])

if (props.catalogs.length > 0) {
  all_attributes?.push({
    title: `<img style='width: 12px; margin-right: 5px;' src='/svg/icons/pdf.svg'/> <span> ${t('catalogs.b2b')}</span>`,
    index: 'catalogs'
  })
}


if (props.is_tile) {
  all_attributes?.push({
    title: `<img style='width: 12px; margin-right: 5px;' src='/images/icons/24x24/drop-silhouette-24x24.png'/><span> ${t('product.spoiler.2.title')}</span>`,
    index: 'cleaning'
  })
}

if (props.is_tile && props.pei_level) {
  all_attributes?.push({
    title: `PEI kvalitets klassificering`,

  })
}

if (props.pdfs) {
  Object.entries(props.pdfs ?? {}).forEach(([key, value]) => {
    all_attributes?.push({
      title: `<img style='width: 12px; margin-right: 5px;' src='/svg/icons/pdf.svg'/> <span>${key}</span>`,
      index: 'pdf_' + key
    })
  })
}


const getTabSlotName = (index) => {
  return `tab-content-${index}`;
}

const getPdfSlotName = (key) => {
  return `tab-content-pdf_${key}`;
}
</script>

<template>
  <div class="product-spoiler">
    <div class="icons-group no-flex full-width" style="gap: 30px;">
      <ProductMarkSKU :sku="sku"/>

      <div class="icons row g-16">
        <template v-for="icons in attribute_icons">
          <div class="icon" v-for="icon in icons.data">
            <img width="32" height="32" :alt="icon.icon_name" class=""
                 :src="'https://hillceramic.se/'+icon.icon">
            <div class="hover-arrow"></div>
            <div class="hover">
              <div>{{ icon.icon_comment }}</div>
            </div>
          </div>
        </template>
      </div>

      <div class="full-width">
        <SpoilerTabs
            :opened="0"
            :tabs="all_attributes">

          <template v-slot:[getTabSlotName(3)]>
            <div class="text">
              <template v-if="niche_id==='Badrum'">
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.badrum.0') }}</Text>
                <br>
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.badrum.1') }}</Text>
                <br>
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.badrum.2') }}</Text>
                <br>
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.badrum.3') }}</Text>
              </template>
              <template v-else>
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.default.0') }}</Text>
                <br>
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.default.1') }}</Text>
                <br>
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.default.2') }}</Text>
                <br>
                <Text text-alignment="justify">{{ $t('product.spoiler.0.content.default.3') }}</Text>
              </template>

              <br>
              <Text text-alignment="justify">{{ $t('product.spoiler.0.content.all.0') }}</Text>
            </div>

            <br>
          </template>
          <template v-slot:[getTabSlotName(4)]>
            <div class="text">
              <Text text-alignment="justify">
                {{ $t('product.spoiler.1.text') }}
              </Text>
              <div class="images row" style="gap: 4px;">
                <img v-for="img in ['Image_215','Image_216','Image_217','Image_218','Image_219',]"
                     :src="'/images/product/eco/'+img+'.jpg'"/>
              </div>
            </div>

            <br>
          </template>
          <template v-slot:tab-content-cleaning>
            <div class="text">
              <Text text-alignment="justify">
                {{ $t('product.spoiler.2.text') }}
              </Text>
              <br>
            </div>
          </template>
          <template v-slot:[getTabSlotName(index)] v-for="(item,index) in attributes">
            <div class="values">
              <template v-for="attribute in item.attributes">
                <div class="value row" v-if="attribute.value">
                  <strong>{{ attribute.site_name }}:</strong> <span>{{ attribute.value }}</span>
                  <template v-if="attribute.ending">{{ attribute.ending }}</template>
                  <template v-if="attribute.site_name === t('product.spoiler_help_attr.collection') ">
                    <nuxt-link :to="'/'+collection_slug" v-if="collection_slug">
                      <ExternalLinkIcon/>
                    </nuxt-link>
                  </template>
                  <template v-if="attribute.site_name === t('product.spoiler_help_attr.size')">
                    <div style="margin-bottom: 3px;">
                      <Help :top="true" :right="true">{{ $t("product.spoiler_help.0") }}</Help>
                    </div>
                  </template>
                  <template v-if="attribute.site_name === t('product.spoiler_help_attr.edge')">
                    <div style="margin-bottom: 3px;">
                      <Help v-if="attribute.value===t('product.spoiler_help_attr.straight')" :top="true" :right="true">
                        {{ $t("product.spoiler_help.1") }}
                      </Help>
                      <Help v-if="attribute.value===t('product.spoiler_help_attr.round')" :top="true" :right="true">
                        {{ $t("product.spoiler_help.2") }}
                      </Help>
                    </div>
                  </template>
                  <template v-if="attribute.site_name === t('product.spoiler_help_attr.right_of_return')">
                    <nuxt-link style="margin-top: 0; margin-bottom: -5px;" to="/faq/kopvillkor/#Ångerrätt">
                      <QuestionIcon width="14px" height="14px" stroke="black"/>
                    </nuxt-link>
                  </template>
                </div>
              </template>
              <br>
            </div>
          </template>
          <template v-slot:tab-content-catalogs>
            <div class="grid-2">
              <CatalogPreview v-for="catalog in props.catalogs"
                              :image="catalog.image" :name="catalog.name"
                              :url="catalog.url" :short_name="catalog.short_name"
                              :id="catalog.id"/>
            </div>
          </template>
          <template v-slot:tab-content-PEI>
            <div class="text">
              <div class="g-8">
                <div class="row g-4">
                  <Text tag="strong">{{ $t('product.spoiler.3.pei.level') }}:</Text>
                  <Text>{{ pei_level }}</Text>
                </div>
                <div>
                  <Text tag="strong">{{ $t('product.spoiler.3.pei.classification_title') }}</Text>
                  <Text>{{ $t('product.spoiler.3.pei.classification_description') }}</Text>
                </div>
                <Text tag="strong">{{ $t('product.spoiler.3.pei.pei_1_title') }}</Text>
                <Text>{{ $t('product.spoiler.3.pei.pei_1_description') }}</Text>
                <div>
                  <Text tag="strong">{{ $t('product.spoiler.3.pei.pei_2_title') }}</Text>
                  <Text>{{ $t('product.spoiler.3.pei.pei_2_description') }}</Text>
                </div>
                <div>
                  <Text tag="strong">{{ $t('product.spoiler.3.pei.pei_3_title') }}</Text>
                  <Text>{{ $t('product.spoiler.3.pei.pei_3_description') }}</Text>
                </div>
                <div>
                  <Text tag="strong">{{ $t('product.spoiler.3.pei.pei_4_title') }}</Text>
                  <Text>{{ $t('product.spoiler.3.pei.pei_4_description') }}</Text>
                </div>
                <div>
                  <Text tag="strong">{{ $t('product.spoiler.3.pei.pei_5_title') }}</Text>
                  <Text>{{ $t('product.spoiler.3.pei.pei_5_description') }}</Text>
                </div>
              </div>
            </div>
          </template>

          <template v-for="[key, value] in Object.entries(pdfs ?? {})" v-slot:[getPdfSlotName(key)]>
            <div class="grid-2 the-same-grid g-32">
              <template v-for="item in value">
                <nuxt-link target="_blank" :to="file.url" v-for="file in item.files">
                  <img class="preview full-width" :src="file.preview"/>

                  <Text style="font-size: 12px;">{{ file.name }}</Text>
                </nuxt-link>
              </template>
            </div>
          </template>
        </SpoilerTabs>
      </div>

      <nuxt-link v-if="main_category" :to="'/'+main_category.url">
        <Button type="light-grey" class="full-width tall">
          {{ $t('product.all_products_in_category') }} "{{ main_category.name }}"
          <ExpandIcon/>
        </Button>
      </nuxt-link>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.text {
  font-size: 13px;
}

.preview {
  box-shadow: 0px 4px 16px 0px #52525214;
}

.product-spoiler {
  background: $white;
  box-shadow: 0 32px 48px rgba(82, 82, 82, .08);
  width: 580px;
  position: relative;
  z-index: 9;
  padding: 32px 50px;
  margin: -56% auto 0;
  justify-content: flex-start;
  align-items: center;

  @include medium {
    width: 100%;
    padding: 32px 20px;
    margin: 0;
  }

  @include smd {
    width: 100%;
    padding: 32px 20px;
    margin: 0;
  }
}

.icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;

  .icon {
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 100%;
    background: #F0EFEC;
    font-size: 20px;
    flex-wrap: wrap;
    position: relative;

    .hover-arrow {
      position: absolute;
      border-bottom: 14px solid $black !important;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-radius: 4px;
      color: $white;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      display: none;
      z-index: 9;
      top: 43px;
      max-width: 10px;
      width: 0;
      height: 0;
    }

    .hover {
      position: absolute;
      background: $black;
      border-radius: 4px;
      color: $white;
      text-align: center;
      line-height: 16px;
      font-size: 11px;
      font-weight: 300;
      padding: 5px 10px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
      z-index: 9;
      top: 55px;
      max-width: 250px;
      width: max-content;
    }

    &:hover {
      background: $yellow;

      .hover-arrow {
        display: block;
      }

      .hover {
        display: block;
      }
    }

    img {
      width: 32px;
    }
  }
}

.values {
  .value {
    gap: 5px;
    font-size: 13px;

    span {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}
</style>
